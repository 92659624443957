#dispatchWrapper {
    .dispatch_content {
        font-weight: 600;

        .list_icon {
            .fa-paper-plane {
                margin-right: 15px;
                color: #0085ec;
            }
        }
    }
}
@media only screen and (max-width: 600px) {
    #dispatchWrapper {}
}
