#gpsWrapper {
  padding: 0;

  .help_text {
    color: grey;
    font-size: 10px;
  }

  .gps_container_wrapper {
    padding: 25px;
    margin: 0px;
    .gps_container {
      padding: 15px;
      color: #6c757d;
      font-size: 14px;
      background-color: #8080800a;
      border-radius: 5px;
      min-height: 280px;

      .gps_header {
        margin-top: 15px;
        margin-bottom: 25px;
      }
    }
  }
      .content-card{
  padding: 20px;
  border: 0;
  background-color: transparent;
  box-shadow: #c2c6ca 2px 4px 10px;
  border-radius: 16px;
  text-align: center;
  min-height: 270px;
  margin-top: 50px;

}
    }
    .gps-img{
    margin-top: 80px;}

@media only screen and (max-width: 1024px) {
  #gpsWrapper {
    .content-card{
      min-height: 350px;
      margin-top: 0px;
  
    }
    .gps-img{
      margin-top: 40px;}
  }
  }
  


@media only screen and (max-width: 768px) {
  #gpsWrapper {
  .content-card{
    min-height: 350px;
    margin-top: 0px;

  }
  .gps-img{
    margin-top: 20px;}
}
}
