$backgroundColor: #f9f9f9;

.text-osrit {
    color: #2196f3;
}

.text-osrit-light-grey {
    color: #cdd4db;
}

.background-osrit {
    background-color: #2196f3;
}

.password {
    margin: auto;
    color: #69b7df;
    margin-top: 10px;
}
