#homeWrapper {
  .home_row {
    margin: 0;
    padding-top: 100px;
    padding-bottom: 100px;

    .text_cursor {
      color: #0084f4;
    }

    .hero_text {
      font-weight: 500;
      line-height: 1.1;
      color: rgb(51, 51, 51);
      font-size: 36px;
      text-align: center;
      max-width: 900px;
      width: 100%;
      margin: 10px auto;
    }
  }

  .home_card_wrapper {
    margin: 0;
    padding-top: 50px;
    padding-bottom: 50px;

    .home_card_col {
      padding: 5px 10px;

      .home_card {
        background-color: #000085ec;
        padding: 30px 15px;
        min-height: 250px;
        color:"#000";

        .home_card_icon {
          text-align: center;
          min-height: 60px;
        }

        .home_card_header {
          margin-top: 5px;
          font-size: 20px;
          text-transform: uppercase;
          font-weight: 400;
        }

        .home_card_title {
          font-weight: 300;
          font-size: 14px;
        }

        .home_card_detail {
          font-weight: 200;
          font-size: 14px;
        }

        .home_card_button_wrapper {
          margin-top: 12px;
          .home_card_button {
            margin-top: 5px;
            color: "#0084f4";
            border-color:"#0084f4";

            &:hover {
              background-color:"#0084f4";
              color: #fff;
            }
          }
        }
      }
    }
  }

  .home_row_three {
    margin: 0;
    padding-top: 50px;

    .header {
      color: #0085ec;
      text-transform: uppercase;
    }
  }

  .home_row_four {
    margin: 0;
    padding-top: 15px;
    padding-bottom: 15px;

    .home_row_four_content {
      padding: 20px;
      border-radius: 10px;
      max-width: 1200px;
      margin: 45px auto;
      background-color: rgba(0, 132, 244, 0.06);

      .home_row_four_content_icon {
        color: #0085ec;
      }

      .home_row_four_content_body {
        // font-size: 20px;
        font-size: 24px;
        font-weight: 200;
        margin-top: 16px;
      }
    }
  }
  .a_{color:white}
}
@media only screen and (max-width: 600px) {
  #homeWrapper {
    .home_row {
      .hero_text {
        font-weight: 400;
        font-size: 22px;
      }
    }
  }
}
