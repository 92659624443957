@import '~font-awesome/css/font-awesome.min.css';
@import 'bootstrap/scss/bootstrap.scss';
@import './_variables';
// @import './_form';
// @import './_banner';
// @import './_header';
// @import './_button';

body {
    margin: 0;
    padding: 0;
    // font-family: sans-serif;
    font-family: 'Ubuntu', sans-serif;
    background-color: $backgroundColor;

    .col-osrit {
        padding-left: 5px;
        padding-right: 5px;
    }
}

.round-card {
    background-color: white;
    padding: 12px;
    border: 0px;
    box-shadow: #c2c6ca 4px 3px 10px;
    text-align: center;
    font-size: 14px;
    border-radius: 10px;
}

img.styled {
    box-shadow: #c2c6ca 4px 3px 10px;
    border-radius: 6px;
}

.center-vertical {
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%)
}


.h1 {
    text-align: center;
}

.more-info-btn {
    width: 60%;
    height: 60px;
    margin-top: 30px;
    box-shadow: #c2c6ca 4px 3px 10px;
    border-radius: 30px;
}

.more-info-btn-color {
    border: "1 px solid #000";
    background-color: #fff;
    color: black;
}

.more-info-btn-color-selected {
    border: "0 px solid #000";
    background-color: #0085e4;
    color: #fff;
}


@media only screen and (max-width: 768px) {
    body {
        background-color: transparent;

        .bg-light {
            background-color: transparent;
        }
    }

    .h1 {
        font-size: 30px;
        text-align: center;
    }

    .h3 {
        font-size: 18px;
    }
}