body {
    max-width: 100%;
    overflow-x: hidden;
}
.svg-background-2 {
background-color: #ffffff;
background-image: url("data:image/svg+xml,%3Csvg width='1600' height='1000' xmlns='http://www.w3.org/2000/svg'%3E%3Ctitle%3Estartup life%3C/title%3E%3Cg%3E%3Ctitle%3Ebackground%3C/title%3E%3Crect fill='none' id='canvas_background' height='802' width='1602' y='-1' x='-1'/%3E%3C/g%3E%3Cg%3E%3Ctitle%3ELayer 1%3C/title%3E%3Cpath stroke='null' id='svg_187' fill='%23e5e5e5' d='m-32.66934,712.91549c-148.7678,81.0471 134.47606,-36.5168 324.83587,-55.45025c190.35981,-18.93345 242.33142,-80.97554 482.06079,-92.07157c220.5424,-19.44983 228.88412,-44.16148 333.03618,-70.04307c237.30343,-41.49141 325.07831,-94.15377 451.29087,-114.6648c126.21256,-20.51104 147.93496,-119.31358 -10.54888,-97.44627c-158.48384,21.86731 -837.69401,231.08707 -920.87663,247.34657l-665.95865,131.51277z'/%3E%3Cpath transform='rotate(3 899.6304931640648,481.344207763671) ' stroke='null' id='svg_186' fill='%23cccccc' d='m-13.62384,710.6778c19.33535,105.87369 12.12754,-9.19873 225.7008,-34.28731c213.57326,-25.08858 322.95894,-129.23709 563.94484,-144.97409c221.69836,-27.58484 233.47039,-42.39899 338.16836,-79.1057c238.54723,-58.84544 176.42365,-82.39821 557.50799,-162.68033c381.08434,-80.28212 6.25356,-98.36454 -153.06097,-67.3511c-159.31453,31.01344 -799.45838,287.28109 -858.95197,283.12706l-697.59555,157.59176z'/%3E%3Cpath transform='rotate(3 896.9252319335951,295.29458618164267) ' stroke='null' id='svg_1' fill='%23469cdd' d='m-7.74312,-109.84888c-72.93188,5.67644 -11.13675,229.4818 -24.77651,286.99039c25.39164,70.86517 25.20751,132.11606 18.81762,204.03462c-6.38988,71.91856 -0.44858,204.52618 -13.42091,302.00097c-12.97232,97.4748 -18.11621,5.10677 289.58859,-58.26431c307.7048,-63.37107 251.97283,-97.76885 666.77268,-158.20624c414.79986,-60.43739 572.77694,-271.64627 815.5989,-204.33192c242.82196,67.31435 -56.254,-369.39901 -143.00659,-389.3944c-86.75259,-19.9954 -657.47604,4.17003 -706.46978,0.68272l-903.104,16.48817z'/%3E%3C/g%3E%3C/svg%3E");/* background-attachment: fixed; */
background-size: cover;
}
.video-col{
    text-align: justify; margin-top: 120px; font-size: 20px; color: white;
}
.footer{
    list-style: none;
     margin-top: 40px;
      margin-left: 120px;
}
.height-svg{
    height:60px
}
@media only screen and (max-width: 800px) {
    .body{
    overflow-x:hidden ;
    width:103%;
}
    .video-col{
        text-align: justify;
         margin-top: 80px; 
         font-size: 17px;
          color: white;
    }
    .para{
        width: 90%;
        margin: auto;
    }
    .para2{
        margin-left: 11%;
    }
    .footer{
     margin-left: auto;
    }
    .height-svg{
        height: 0px;
    }
  } 
  @media only screen and (max-width: 1024px) {
    .body{
    overflow-x:hidden ;
    width:100%;
}
}