#homeBanner {
  margin: 0;
  padding: 10px 5px;
  background-color: #0085e4;
  color: white;
  font-size: 15px;

  .home_banner_btn {
    color: white;
    border: 1px solid #f9f6f6;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 12px;
    text-decoration: none;
    background-color: transparent;
    min-width: 80px;
    font-weight: 300;

    &.demo {
      padding: 3px 10px;
      margin-right: 10px;
    }
  }
      .home_banner_sales a{
      color: white;
    }
    .home_banner_sales a:hover{
      color: white;
    }

}
@media only screen and (max-width: 600px) {
  #homeBanner {
    .home_banner_sales {
      font-size: 12px;
      margin-top: 5px;
    }
    .home_banner_sales a{
      color: white;
    }
    .home_banner_sales a:hover{
      color: white;
    }

  }
}
