#privacyWrapper {
    .policy_content {
        margin: 25px auto;
        width: 100%;
        max-width: 1000px;

        .policy_header {
            margin: 25px auto;
        }

        .policy_title {
            margin-top: 25px;
            margin-bottom: 10px;
        }
    }
}
@media only screen and (max-width: 600px) {
    #privacyWrapper {
        .policy_content {}
    }
}
