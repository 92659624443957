#termsWrapper {
    .terms_content {
        margin: 25px auto;
        width: 100%;
        max-width: 1000px;

        .terms_header {
            margin: 25px auto;
        }

        .terms_title {
            margin-top: 25px;
            margin-bottom: 10px;
        }
    }
}
@media only screen and (max-width: 600px) {
    #termsWrapper {
        .terms_content {}
    }
}
